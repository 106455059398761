export const adminSidebar = [
  {
    id: 9,
    title: 'Dashboard',
    path: '/dashboard',
  },
  {
    id: 1,
    title: 'Membership Management',
    path: '/users',
  },
  {
    id: 7,
    title: 'Content Management',
    path: '/content',
  },
  {
    id: 2,
    title: 'Admin Management',
    path: '/admin',
  },
  {
    id: 3,
    title: 'Notifications',
    path: '/notifications',
  },
  {
    id: 4,
    title: 'Benefits',
    path: '/benefits',
  },
  {
    id: 9,
    title: 'Missing Person Reports',
    path: '/reports',
  },
  {
    id: 5,
    title: 'Solutions',
    path: '/solution',
  },
  {
    id: 6,
    title: 'Analytics',
    path: '/analytics',
  },
  {
    id: 8,
    title: 'Safe Return',
    path: '/safe-return',
    roles: [],
  }
]

export const viewerSidebar = [
  {
    id: 9,
    title: 'Dashboard',
    path: '/dashboard',
  },
  {
    id: 1,
    title: 'Membership Management',
    path: '/users',
  },
  {
    id: 7,
    title: 'Content Management',
    path: '/content',
  },
  {
    id: 2,
    title: 'Admin Management',
    path: '/admin',
  },
  {
    id: 3,
    title: 'Notifications',
    path: '/notifications',
  },
  {
    id: 4,
    title: 'Benefits',
    path: '/benefits',
  },
  {
    id: 5,
    title: 'Solutions',
    path: '/solution',
  },
  // {
  //   id: 6,
  //   title: 'Analytics',
  //   path: '/analytics',
  // },
  {
    id: 8,
    title: 'Safe Return',
    path: '/safe-return',
    roles: [],
  }
]

export const partnerSidebar = [
  // {
  //   id: 1,
  //   title: 'Dashboard',
  //   path: '/dashboard',
  // },
  {
    id: 2,
    title: 'Membership',
    path: '/users',
  },
  {
    id: 3,
    title: 'Safe Return',
    path: '/safe-return',
  },
  
  // {
  //   id: 4,
  //   title: 'Relationship',
  //   path: '/relationship',
  // },
]